import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth/services/auth.service';

export const LogOutGuard = () => {
  const router = inject(Router);
  const authService = inject(AuthService);

  const isLogOut = authService.isLogOut();
  if (!isLogOut) router.navigate(['']);
  return isLogOut;
}
