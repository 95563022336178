import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@auth/services/auth.service';
import {
  of,
  catchError,
  EMPTY,
  Observable,
  switchMap,
  identity,
  map,
} from 'rxjs';
import { environment } from 'src/environments/environment';

const AUTHORIZATION = {
  basicPrefix: 'Basic',
  bearerPrefix: 'Bearer',
  headerName: 'Authorization',
};

type beforeFunc = (
  request: HttpRequest<unknown>,
) => Observable<HttpRequest<unknown>>;
type prepareFunc = (request: HttpRequest<unknown>) => HttpRequest<unknown>;
type onErrorFunc = (
  error: HttpRequest<unknown>,
  caught: Observable<HttpEvent<any>>,
  request: HttpRequest<unknown>,
  next: HttpHandler,
) => Observable<HttpEvent<any>>;
type afterFunc = (event: HttpEvent<unknown>) => Observable<HttpEvent<unknown>>;

type Handlers = {
  before?: beforeFunc;
  prepareRequest?: prepareFunc;
  onError?: { [key: number]: onErrorFunc };
  after?: afterFunc;
};
type EnpointsHandlers = {
  [endpointUrl: string]: Handlers;
};

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  endpointHandlers: EnpointsHandlers = {
    [environment.api.graphqlUrl]: {
      before: this.refreshIfExpired,
      prepareRequest: this.prepareGraphQLRequest,
      onError: {
        401: this.refreshTokenAndRetry,
      },
    },
    [environment.api.downloadEndpoint]: {
      before: this.refreshIfExpired,
      prepareRequest: this.prepareGraphQLRequest,
      onError: {
        401: this.refreshTokenAndRetry,
      },
    },
    [environment.jwt.url]: {
      prepareRequest: this.prepareKeycloackRequest,
    },
    [environment.jwt.logout]: {
      prepareRequest: this.prepareGraphQLRequest,
    },
  };

  constructor(private authService: AuthService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const endpoint = Object.keys(this.endpointHandlers)
      .sort((a, b) => b.length - a.length)
      .find((endpoint) => request.url.includes(endpoint));
    const handlers = endpoint ? this.endpointHandlers[endpoint] : {};

    const prepareFunc = handlers?.prepareRequest ?? identity;
    const beforeFunc = handlers?.before ?? of;
    const afterFunc = handlers?.after ?? of;

    return of(request).pipe(
      switchMap((req) => beforeFunc.bind(this)(req)),
      map((req) => prepareFunc.bind(this)(req)),
      switchMap((req) => next.handle(req)),
      catchError((err, caught) => {
        const errorHandler = handlers?.onError?.[err.status];
        if (errorHandler)
          return errorHandler.bind(this)(err, caught, request, next);
        else {
          throw err;
        }
      }),
      switchMap((req) => afterFunc.bind(this)(req)),
    );
  }

  // Before Functions

  private refreshIfExpired(
    request: HttpRequest<unknown>,
  ): Observable<HttpRequest<unknown>> {
    if (!this.authService.isLogged()) {
      return this.authService.refreshToken().pipe(
        catchError((error, caught) => {
          return this.logout(caught);
        }),
        map((e) => request),
      );
    }
    return of(request);
  }

  //Prepare Functions

  private prepareKeycloackRequest(
    req: HttpRequest<unknown>,
  ): HttpRequest<unknown> {
    const basicAuth: string = this.authService.getKeycloakBasicAuth();
    const token = `${AUTHORIZATION.basicPrefix} ${basicAuth}`;
    return req.clone({
      headers: req.headers.append(AUTHORIZATION.headerName, token),
    });
  }

  private prepareGraphQLRequest(
    req: HttpRequest<unknown>,
  ): HttpRequest<unknown> {
    const accessToken: string | null = this.authService.getAccessToken();
    if (accessToken === null) {
      this.authService.logout();
      return req;
    }
    const token = `${AUTHORIZATION.bearerPrefix} ${accessToken}`;
    return req.clone({
      headers: req.headers.append(AUTHORIZATION.headerName, token),
    });
  }

  // onError Functions

  private logout(caught: Observable<unknown>) {
    this.authService.logout();
    console.warn('401 - disconnected');
    return EMPTY;
  }

  private refreshTokenAndRetry(
    error: HttpRequest<unknown>,
    caught: Observable<HttpEvent<any>>,
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return this.authService.refreshToken().pipe(
      switchMap(() => next.handle(this.prepareGraphQLRequest(request))),
      catchError(() => {
        this.authService.logout();
        console.warn('401 - disconnected');
        throw error;
      }),
    );
  }

  //After functions
}
