import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, RouterOutlet } from '@angular/router';
import { LanguagesService } from '@shared/services/languages.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    RouterOutlet,
    MatDialogModule,
  ],
  providers: [
    MatSnackBarModule,
  ]
})
export class AppComponent implements OnInit {
  private router: Router = inject(Router);
  private dialog = inject(MatDialog);
  private matIconRegistry = inject(MatIconRegistry);
  private domSanitizer = inject(DomSanitizer);
  private languagesService = inject(LanguagesService);
  private destroyRef = inject(DestroyRef);

  constructor(
  ) {
    this.languagesService.init();
    this.addCustomMatIcons();
  }

  ngOnInit() {
    this.router.events.pipe(
        takeUntilDestroyed(this.destroyRef),
    ).subscribe(e => this.dialog.closeAll());
  }

  private addCustomMatIcons() {
    this.matIconRegistry.addSvgIconResolver((name, namespace) => {
      switch(namespace) {
        case "flags" :
          return this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/i18n/countries/${name}.svg`)
        default :
          return this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icon/material/${name}.svg`)
      }
    });
  }
}
