import { Routes } from '@angular/router';
import { LoggedGuard } from '@auth/guards/LoggedGuard';
import { LogOutGuard } from '@auth/guards/LogOutGuard';
import { RoleGuard } from '@auth/guards/role.guard';

export const AppRoutes: Routes = [
  {
    path: 'workspace',
    loadChildren: () => import('@workspace/workspace.routes').then(r => r.WorkspaceRoutes),
    canActivate: [ LoggedGuard ],
  },
  {
    path: 'administration',
    loadChildren: () => import('@administration/administration.routes').then(r => r.AdministrationRoutes),
    canActivate: [ LoggedGuard, RoleGuard ],
    data: { roles: ['Administrator'] },
  },
  {
    path: 'login',
    loadComponent: () => import('@auth/login/login.component').then(c => c.LoginComponent),
    canActivate: [ LogOutGuard ],
  },
  {
    path: '**',
    redirectTo: 'workspace',
  },
];
