import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth/services/auth.service';


export const LoggedGuard = () => {
  const router = inject(Router);
  const authService = inject(AuthService);

  const isLogged = authService.isLogged();
  if (!isLogged) router.navigate(['login']);
  return isLogged;
}
