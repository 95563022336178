import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom, LOCALE_ID } from '@angular/core';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { ApolloModule } from '@app/apollo/apollo.module';
import { AppRoutes } from '@app/app.routes';
import { AuthInterceptor } from '@auth/interceptors/auth.interceptor';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CustomMatPaginatorIntl } from '@shared/translations/CustomMatPaginatorIntl';
import { Apollo } from 'apollo-angular';
import { MarkdownModule } from 'ngx-markdown';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}

export const AppConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      BrowserModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [ HttpClient ],
        },
      }),
      ApolloModule,
      MatNativeDateModule,
      MarkdownModule.forRoot(),
      MatSnackBarModule,
      MatDialogModule,
    ),
    {
      provide: MatPaginatorIntl,
      useClass: CustomMatPaginatorIntl,
    },
    {
      provide: LOCALE_ID,
      useValue: 'en-US',
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    Apollo,
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
    provideRouter(AppRoutes),
    MatSnackBarModule,
  ],
}
